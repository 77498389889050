import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      
      <circle class="cls-1" cx="50" cy="50" r="22.55" stroke="black" strokeWidth="45"  
      />
      <g id="B" transform="translate(33.000000, 31.000000)">
        <g transform="scale(0.2)">
      <polygon class="cls-1" points="131.38 0 131.38 174.26 177.29 174.26 177.29 174.26 131.38 87.13 177.29 0 177.29 0 131.38 0" fill="white"/><polygon class="cls-1" points="0 0 0 174.26 0 174.26 44.64 109.12 44.64 109.12 0 87.13 44.64 65.38 44.64 65.38 0 0 0 0" fill="white"/><polygon class="cls-1" points="65.91 174.45 65.91 0.19 65.91 0.19 110 65.38 110 65.38 65.91 87.22 109 174.26 109 174.26 65.91 174.45" fill="white"/> 
      </g></g> 
    </g>
  </svg>
);

export default IconLoader;
