import React from 'react';

const IconCnd = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="2011.000000pt" height="2010.000000pt" viewBox="0 0 2011 2010"
    preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,2010.000000) scale(0.100000,-0.100000)"
   fill="#000000" stroke="none">
   <title>Coffee&Donut</title>
   <path d="M9605 20093 c-528 -31 -803 -60 -1195 -124 -2071 -340 -3984 -1327
   -5475 -2824 -1956 -1963 -3007 -4618 -2925 -7388 41 -1353 333 -2628 885
   -3852 99 -221 317 -646 437 -855 866 -1500 2085 -2743 3560 -3628 1418 -850
   2988 -1326 4661 -1413 296 -15 1001 -7 1272 15 1128 92 2180 349 3180 776
   1461 624 2753 1587 3780 2817 785 939 1403 2042 1796 3203 353 1042 529 2115
   529 3229 0 2236 -724 4365 -2089 6141 -258 336 -510 620 -855 966 -385 385
   -671 634 -1076 938 -1605 1204 -3485 1886 -5505 1996 -168 9 -846 11 -980 3z
   m2005 -1833 c284 -17 454 -45 660 -107 154 -46 235 -82 337 -148 250 -163 402
   -428 435 -756 15 -145 1 -305 -36 -409 -87 -250 -350 -487 -650 -584 -118 -39
   -340 -69 -461 -63 -263 13 -576 186 -731 402 -67 95 -101 173 -137 312 -53
   211 -43 338 51 643 38 123 46 170 37 236 -6 51 -9 56 -48 76 -53 27 -195 36
   -305 18 -186 -30 -483 -137 -763 -275 -86 -42 -102 -54 -142 -109 -136 -186
   -208 -446 -209 -756 0 -210 29 -411 108 -740 44 -186 163 -587 213 -723 151
   -406 448 -952 684 -1256 104 -135 340 -372 481 -483 334 -267 698 -456 1041
   -544 92 -23 132 -28 240 -28 160 -1 249 20 410 98 236 114 667 437 815 611 25
   29 88 128 140 220 98 172 177 285 220 317 21 15 31 16 64 7 64 -18 99 -64 120
   -161 4 -16 13 -28 21 -28 20 0 19 -14 -11 -116 -50 -174 -104 -254 -318 -472
   -242 -247 -475 -422 -857 -642 -562 -325 -1273 -563 -1894 -636 -186 -22 -625
   -25 -790 -5 -249 29 -502 86 -708 157 -784 273 -1455 984 -1762 1866 -38 110
   -157 538 -177 638 -19 96 -16 427 6 575 57 401 169 756 323 1029 196 346 510
   680 896 949 357 250 996 578 1385 711 422 144 860 203 1312 176z m5070 -5246
   c174 -19 454 -76 583 -118 329 -108 606 -284 877 -555 158 -159 256 -280 402
   -496 217 -320 380 -640 491 -960 74 -214 204 -772 217 -934 22 -267 -36 -587
   -171 -949 -189 -506 -453 -852 -911 -1195 -304 -227 -645 -405 -1079 -563
   -161 -59 -832 -264 -1374 -420 -540 -156 -939 -278 -1300 -398 -373 -124 -512
   -177 -745 -286 -85 -39 -161 -74 -168 -77 -7 -3 11 32 40 78 111 179 183 367
   220 569 16 92 21 160 22 340 1 386 -48 655 -259 1397 -271 953 -433 1404 -690
   1912 -263 522 -494 818 -762 977 -165 98 -320 159 -434 172 -34 4 -64 12 -66
   17 -1 6 192 81 430 167 237 87 542 198 677 248 135 50 556 203 935 340 380
   137 743 271 809 299 148 64 404 157 561 204 254 77 699 182 940 222 214 36
   480 39 755 9z m-9405 -264 c243 -27 451 -84 652 -180 445 -212 745 -499 938
   -895 248 -511 252 -1058 11 -1557 -262 -544 -736 -932 -1439 -1179 -59 -21
   -69 -22 -77 -9 -5 8 -38 56 -74 105 l-64 90 63 65 c124 127 189 267 238 504
   20 99 22 141 22 391 0 219 -4 305 -18 395 -62 400 -167 747 -323 1070 -95 196
   -181 325 -297 447 -107 112 -184 169 -299 222 -114 54 -184 71 -318 78 -194 9
   -431 -48 -605 -148 -92 -52 -230 -178 -287 -262 -61 -90 -126 -235 -153 -343
   -132 -516 63 -1155 570 -1869 639 -901 1379 -1548 2393 -2093 73 -40 137 -72
   140 -72 4 0 18 80 30 178 126 985 511 1674 1182 2115 343 225 709 344 1160
   377 390 30 695 -45 980 -241 240 -164 406 -385 472 -629 20 -75 23 -108 23
   -275 0 -147 -5 -209 -19 -275 -68 -315 -214 -535 -460 -695 -172 -112 -328
   -158 -541 -158 -269 1 -497 69 -665 198 -111 86 -235 234 -295 354 -14 29 -42
   99 -61 155 -99 285 -223 469 -357 532 -95 44 -240 30 -377 -37 -81 -41 -225
   -181 -295 -289 -191 -294 -327 -760 -391 -1333 -9 -81 -14 -153 -11 -161 7
   -17 262 -130 482 -213 322 -122 683 -215 1005 -260 178 -24 589 -24 755 0 421
   63 800 210 1095 427 47 34 141 119 210 189 197 199 271 321 359 595 107 330
   131 550 91 810 -12 81 -21 148 -19 150 2 2 68 23 145 48 l141 45 57 -176 c200
   -626 226 -1197 86 -1891 -58 -289 -180 -599 -316 -800 -172 -257 -434 -509
   -729 -702 -442 -290 -1066 -490 -1745 -560 -189 -19 -678 -16 -880 5 -326 36
   -672 106 -980 198 -74 23 -138 42 -142 42 -4 1 -27 -46 -52 -104 -231 -564
   -608 -939 -1284 -1278 -790 -396 -1655 -603 -2307 -552 -739 58 -1274 291
   -1746 761 -160 159 -242 258 -353 425 -228 342 -364 687 -423 1075 -24 155
   -24 518 0 673 91 595 338 1077 777 1518 262 262 586 477 967 639 81 34 150 64
   152 66 2 2 -28 68 -67 146 -168 340 -266 641 -319 978 -25 163 -25 541 0 695
   132 804 614 1486 1361 1924 461 271 1117 485 1669 545 148 17 439 19 562 6z"/>
   <path d="M14615 11996 c-68 -16 -212 -66 -292 -101 l-53 -24 6 -48 c63 -538
   192 -1106 375 -1657 61 -181 212 -594 289 -786 211 -530 653 -1152 990 -1392
   122 -87 280 -169 416 -214 155 -52 188 -53 310 -10 134 47 242 97 331 152 80
   50 110 90 183 247 124 265 178 556 167 902 -12 353 -87 711 -219 1043 -60 151
   -268 582 -334 690 -81 134 -194 273 -349 427 -389 390 -754 611 -1179 716
   -230 56 -527 81 -641 55z"/>
   <path d="M4162 8093 c-126 -185 -213 -405 -253 -638 -28 -164 -30 -442 -5
   -610 93 -619 482 -1261 998 -1648 411 -308 950 -537 1443 -613 176 -27 506
   -25 669 5 367 66 625 235 818 536 47 72 128 227 128 244 0 5 -10 13 -22 17
   -67 20 -471 200 -653 290 -641 317 -1360 772 -1830 1159 -460 378 -892 847
   -1164 1263 -29 45 -57 82 -61 82 -4 0 -35 -39 -68 -87z"/>
   </g>
   </svg>);
   

export default IconCnd;
