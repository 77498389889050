import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sr from '@utils/sr';
import { srConfig } from '@config';
import Img from 'gatsby-image';
import { FormattedIcon } from '@components/icons';
//import * as from '@utils/sliders';
import styled from 'styled-components';
import { theme, mixins, media, Section, Button } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledRow = styled(Section)`
  ${mixins.flexBetween};
  width:100%;
  padding: 40px 40px;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`padding: 40px 5px;
  `};

`;
const StyledTitle = styled.h4`
  margin: 0 auto;
  font-size: ${fontSizes.h3};
  ${media.tablet`font-size: 24px;`};
  a {
    display: block;
  }
`;
const StyledArchiveLink = styled(Link)`
  ${mixins.inlineLink};
  text-align: center;
  margin: 0 auto;
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.sm};
  &:after {
    bottom: 0.1em;
  }
`;
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  text-align: center;
  border-radius: ${theme.borderRadius};
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};
` ;


const StyledText = styled.h3`
  
  ${media.tablet`
    
    font-size: 15px;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};

  a {
    display: block;
  }
` ;
const StyledImgContainer = styled.a`
  ${mixins.boxShadow};
  z-index: 1;
  width:100%;
  position:relative;
  background-color: ${colors.green};
  border-radius: ${theme.radius + 1}px;
  transition: ${theme.transition};
  ${media.tablet`height: 100%;`};
  ${media.phone`
    
    opacity: 1;
  `};
  &:hover,
  &:focus {
    background: transparent;
    &:before,
    ${StyledFeaturedImg} {
      background: transparent;
      filter: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: ${theme.transition};
    background-color: ${colors.navy};
    mix-blend-mode: screen;
  }
`;



const Cnd = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title, external,behance , cover} = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), [])
  
//
  
//
  return (
    <StyledContainer>
      <StyledTitle >Graphic Design Archive<br></br><br></br></StyledTitle>
  
      <StyledImgContainer  href={external ? external : github ? github : '#'}>
      <StyledFeaturedImg fluid={cover.childImageSharp.fluid} alt={title} /> 
      
                </StyledImgContainer>
       <StyledRow>
       <a  href="https://www.instagram.com/coffeendonut_"><StyledText>@coffeendonut_</StyledText></a>
       <a href="https://www.instagram.com/brkdesign_"><StyledText >@brkdesign_</StyledText></a>
       </StyledRow> 
    </StyledContainer>
  );
};


Cnd.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Cnd;
