import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { email } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
//import Comp1 from './Comp1.mp4';
import Comp1 from './blackGif.gif';
const { colors, fontSizes, fonts, navDelay, loaderDelay } = theme;

const StyledRow = styled(Section)` 
 padding:0 0;
display:grid;
  
  width:100%;
  grid-template-columns: auto auto;
 
`;
const StyledContainer = styled(Section)`
padding-top: 25%;
z-index:2;
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  
  min-height:100vh;
  float:left;
  ${media.tablet`padding-top: 150px; `};
  div {
    width: 100%;
  }
`;


const StyledOverline = styled.h1`
  color: ${colors.green};
  
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  z-index:2;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  ${media.tablet`font-size: ${fontSizes.smish};`};
`;
const StyledTitle = styled.h2`
  font-size: 80px;
  line-height: 1.1;
  margin: 0;
  text-shadow: 1px 1px 40px black;
  z-index:2;
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledSubtitle = styled.h3`
  font-size: 80px;
  line-height: 1.1;
  color: ${colors.white};
  z-index:2;
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledDescription = styled.div`
  margin-top: 25px;
  width: 50%;
  max-width: 500px;
  
  z-index:2;
  a {
    ${mixins.inlineLink};
  }
`;
const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  z-index:2;
  margin-top: 50px;
  
`;


const StyledVid = styled(Section)`
padding:0 0;
width:70%;
margin-left: 25%;
${media.tablet`width:78%;padding-top: 100px;  margin-left: 15%;`};
  position: absolute;
  
  z-index:-1;
  color: red;

`;

const Hero = ({ data }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const { frontmatter, html } = data[0].node;

  const one = () => (
    <StyledOverline style={{ transitionDelay: '100ms' }}>{frontmatter.title}</StyledOverline>
  );
  const two = () => (
    <StyledTitle style={{ transitionDelay: '200ms' }}>{frontmatter.name}.</StyledTitle>
  );
  const three = () => (
    <StyledSubtitle style={{ transitionDelay: '300ms' }}>{frontmatter.subtitle}</StyledSubtitle>
  );
  const four = () => (
    <StyledDescription
      style={{ transitionDelay: '400ms' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
  const five = () => (
    <div style={{ transitionDelay: '500ms' }}>
      <StyledEmailLink href={`mailto:${email}`}>Get In Touch</StyledEmailLink>
    </div>
  );
 /* const six = () => (
    <div style={{ transitionDelay: '50ms' }}>
    <StyledVid> <video width='100%'height='100%' autoplay="autoplay">
    <source src="/Comp1.mp4" type="video/mp4 " />
    Your browser does not support the video tag. </video> </StyledVid>
    </div>
  );*/


  const items = [one, two, /*three,*/ four, five]; // No SUBT
 

/* ADD THIS AS A COMPONENT UNDER TRANSITION GROUP<StyledVid> <video width='70%'height='70%' autoplay="autoplay">
<source src="/Comp1.mp4" type="video/mp4 " />
Your browser does not support the video tag. </video> </StyledVid> */

      /* <StyledVid> <video width='100%'height='100%' autoplay="autoplay">
       <source src={Comp1} type="video/gif " />
       Your browser does not support the video tag. </video> </StyledVid> */

  return (
    <StyledRow>
    <StyledContainer>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              {item}
            </CSSTransition>
          ))}
      </TransitionGroup>
      </StyledContainer>
  <StyledVid>
    <img src={Comp1} alt="Description of GIF" style={{ width: '100%', height: '100%' }} />
  </StyledVid>
    </StyledRow>
  );
};

Hero.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Hero;
